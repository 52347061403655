//add active class when element is visible to play animation
function createObserver(boxElement) {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  };

  const addActiveClass = (entries) => {
    const isVisible = entries[0].isIntersecting;
    if (!boxElement.classList.contains('active')) {
      if (isVisible) {
        boxElement.classList.add('active');
      }
    }
  };

  const observer = new IntersectionObserver(addActiveClass, options);
  observer.observe(boxElement);
}

const blockBubbles = document.querySelector('.block-bubbles');
if (blockBubbles) {
  createObserver(blockBubbles);
}

// Move block-moving-title title on scroll

let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
// Set/update the viewportWidth value
const setViewportWidth = function () {
  viewportWidth = window.innerWidth || document.documentElement.clientWidth;
};
// On resize events, recalculate viewportWidth
window.addEventListener(
  'resize',
  function () {
    setViewportWidth();
  },
  false
);

const movingTitle = document.querySelector('.block-moving-title__title');
const movingTitleContainer = document.querySelector(
  '.block-moving-title__left'
);

if (movingTitle && 1024 < viewportWidth) {
  window.addEventListener('scroll', () => {
    const scrollPercentage =
      (window.scrollY /
        (movingTitleContainer.offsetHeight - window.innerHeight)) *
      100;
    const translateY = -(scrollPercentage / 3) + 'px'; // Ajustez la vitesse de déplacement ici

    movingTitle.style.transform = `rotateZ(-90deg) translateY(${translateY})`;
  });
}
